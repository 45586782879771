import "../../Views/Home/Home.css";
import style from "./Vendas.module.css";
import Navbar from "../../componentes/navbar/Navbar";
import Banner from "../../componentes/banner-venda/Banner";
import { useState } from "react";
import VendaEtapa from "../../componentes/venda-etapa/vendaEtapa";
export default function Vendas() {
  const [vendaComplete, setVendaComplete] = useState(false);
  return (
    <div className="central">
      <Navbar />
      <div className={style.venda}>
        { !vendaComplete ? <Banner logar={() => window.location.href = "/login"} vendaComplete={() => setVendaComplete(true)} /> : <VendaEtapa />}
      </div>
    </div>
  );
}
