import "./Home.css";
import Navbar from "../../componentes/navbar/Navbar";
import Buscador from "../../componentes/buscador/Buscador";
import Conteudo from "../../componentes/conteudo/Conteudo";
import Marcas from "../../componentes/marcas/Marcas";
import Veiculos from "../../componentes/veiculos/Veiculos";

import { useEffect, useState } from "react";
import { Analytics } from "@vercel/analytics/react";
function Home() {
  const [marcas, setMarcas] = useState();
  const [carros, setCarros] = useState([]);

  useEffect(() => {
    fetch("https://parallelum.com.br/fipe/api/v2/cars/brands")
      .then((response) => response.json())
      .then((resultado) => setMarcas(resultado))
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  useEffect(() => {
    fetch("https://webapicarlab.azurewebsites.net/Carro")
      .then((response) => response.json())
      .then((resultado) => setCarros(resultado))
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  return (
    <div className="central">
      <Analytics/>
      <Navbar />
      <Conteudo marcas={marcas} />
      <Marcas marcas={marcas} />
      <Veiculos carros={carros} buscador={true} titulo={"Em oferta"} />
    </div>
  );
}

export default Home;
