import "./Conteudo.css";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
import { Link } from "react-router-dom";
import { Dropdown, DropdownItem } from "flowbite-react";
import { useState, useEffect } from "react";
function Conteudo(props) {
  useEffect((x) => {
    if (localStorage.getItem("marcaId")) {
      var marcaName = localStorage.getItem("marcaname");
      var marcaId = localStorage.getItem("marcaId");
      var marca = {
        name: marcaName,
        code: marcaId,
      };

      GetModelos(marca);
    }
  });
  function GetModelos(marca) {
    if (marca.code != 0 && marca.code != marcaId) {
      var x = [];
      setMarca(marca.name);
      setMarcaId(marca.code);
      setModelo("Qualquer modelo");
      fetch(
        "https://parallelum.com.br/fipe/api/v2/cars/brands/" +
          marca.code +
          "/models"
      )
        .then((response) => response.json())
        .then((resultado) => setModelos(resultado))
        .catch((err) => {
          console.error("ops! ocorreu um erro" + err);
        });

    }

    if(marca.code == 0){
      setMarca(marca.name);
      setMarcaId(0);
      setModelo("Qualquer modelo");
    }
  }

  useEffect((x) => {
    if (
      localStorage.getItem("marcaname") &&
      localStorage.getItem("marcaname") != "Qualquer marca"
    ) {
      fetch(
        "https://parallelum.com.br/fipe/api/v2/cars/brands/" +
          localStorage.getItem("marcaId") +
          "/models"
      )
        .then((response) => response.json())
        .then((resultado) => setModelos(resultado))
        .catch((err) => {
          console.error("ops! ocorreu um erro" + err);
        });
    }
  }, []);

  const [condicao, setCondicao] = useState(
    localStorage.getItem("condicao") || "Usado e novo"
  );
  const [marcaObj, setMarca] = useState(
    localStorage.getItem("marcaname") || "Qualquer marca"
  );
  const [marcaId, setMarcaId] = useState(localStorage.getItem("marcaId") || 0);
  const [modeloObj, setModelo] = useState(
    localStorage.getItem("modeloname") || "Qualquer modelo"
  );
  const [marcas, setMarcas] = useState([]);
  const [modelos, setModelos] = useState([]);
  return (
    <div className="conteudo-central">
      <div className="elemento">
        <div className="header-titulo">
          Encontre carros usados e semi-novos perto de você
        </div>
        <div className="header-titulo-principal">Encontre seu novo carro</div>

        <div className="seletor-tipo">
          <div>Carros</div>
        </div>
        <div className="modal-pesquisa">
          <div className="select-estiloso">
            <div className="flex">
              <Dropdown label={condicao} inline className=" bg-white">
                {" "}
                <Dropdown.Item
                  onClick={(x) => {
                    setCondicao("Usado e novo");
                    localStorage.setItem("condicao", "Usado e novo");
                  }}
                >
                  Usado e novo
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={(x) => {
                    setCondicao("Usado");
                    localStorage.setItem("condicao", "Usado");
                  }}
                >
                  Usado
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={(x) => {
                    setCondicao("Novo");
                    localStorage.setItem("condicao", "Novo");
                  }}
                >
                  Novo
                </Dropdown.Item>
              </Dropdown>
            </div>
          </div>

          <div className="linha"></div>

          <div className="select-estiloso">
            <div className="flex">
              <Dropdown
                label={marcaObj}
                inline
                className=" bg-white h-48 overflow-y-auto overflow-x-hidden"
              >
                {" "}
                {props.marcas != undefined &&
                  props.marcas.map((marca) => {
                    return (
                      <Dropdown.Item
                        onClick={(x) => {
                          GetModelos(marca);
                          localStorage.setItem("marcaname", marca.name);
                          localStorage.setItem("marcaId", marca.code);
                          if(props.marcas[0].name != "Qualquer marca"){
                            props.marcas.unshift({name : 'Qualquer marca', code : '0'});
                          }
                        }}
                      >
                        {marca.name}
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown>
            </div>
          </div>

          <div className="linha"></div>

          <div className="select-estiloso">
            <div className="flex">
              <Dropdown
                label={modeloObj}
                inline
                className=" bg-white max-h-48 overflow-x-auto"
              >
                {" "}
                {modelos != undefined &&
                  modelos.map((modelo) => {
                    return (
                      <Dropdown.Item
                        onClick={(x) => {
                          setModelo(modelo.name);
                          localStorage.setItem("modeloname", modelo.name);
                          localStorage.setItem("modeloId", modelo.code);
                          if(modelos[0].name != "Qualquer modelo"){
                            console.log("aconteceu mamaoc");
                            var x = modelos;
                            x.unshift({name : 'Qualquer modelo', code : '0'});
                            setModelos(x);
                          }
                        }}
                      >
                        {modelo.name}
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown>
            </div>
          </div>

          <Link to={"/carro"} className="a-btn">
            <div className="btn-pesquisa">
              <SearchIcon />
              Buscar carros
            </div>
          </Link>
        </div>

        <div className="header-titulo top-10">Procure por categoria</div>

        <div className="lista-modelos">
          <Link to={"/carro"}>
            <div
              className="modelo"
              onClick={(x) => localStorage.setItem("categoria", "Espaçoso")}
            >
              <TimeToLeaveIcon />
              Espaçoso
            </div>
          </Link>

          <Link to={"/carro"}>
            <div className="modelo">
              <TimeToLeaveIcon />
              Esportivo
            </div>
          </Link>

          <Link to={"/carro"}>
            <div className="modelo">
              <TimeToLeaveIcon />
              Econômico
            </div>
          </Link>

          <Link to={"/caarro"}>
            <div className="modelo">
              <TimeToLeaveIcon />
              Luxo
            </div>
          </Link>
          <div className="modelo">
            <TimeToLeaveIcon />
            Elétrico
          </div>
        </div>
      </div>
    </div>
  );
}

export default Conteudo;
