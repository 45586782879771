import Buscador from "../../componentes/buscador/Buscador";
import "swiper/css";
import { isMobile } from "react-device-detect";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "photoswipe/dist/photoswipe.css";
import CallMadeIcon from "@mui/icons-material/CallMade";
import Navbar from "../../componentes/navbar/Navbar";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import { Gallery, Item } from "react-photoswipe-gallery";
import LocalGasStationOutlinedIcon from "@mui/icons-material/LocalGasStationOutlined";
import FormatColorFillOutlinedIcon from "@mui/icons-material/FormatColorFillOutlined";
import ArticleIcon from "@mui/icons-material/Article";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import "swiper/css/scrollbar";
import "./ViewCarro.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Veiculos from "../../componentes/veiculos/Veiculos";
import { Button } from "@nextui-org/react";
import AttachMoney from "@mui/icons-material/AttachMoney";
function ViewCarro() {
  const { id } = useParams();
  const [state, setState] = useState(2);
  const [carro, setCarro] = useState();

  useEffect((x) => {
    fetch("https://webapicarlab.azurewebsites.net/Leads/criar-lead", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({ idAnuncio: id, ipLead: "192" }),
    })
      .then((response) => response.json())
      .then((resultado) => setCarro(resultado))
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  useEffect((x) => {
    fetch("https://webapicarlab.azurewebsites.net/Carro/GetCarro?id=" + id)
      .then((response) => response.json())
      .then((resultado) => setCarro(resultado))
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  useEffect(() => {
    if (isMobile) {
      setState(1);
    } else {
      setState(2);
    }
  });
  const images = [
    {
      original: "https://picsum.photos/id/1018/1000/600/",
      thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
      original: "https://picsum.photos/id/1019/1000/600/",
      thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
  ];
  return (
    <div className="central">
      <Navbar />
      {carro != undefined && (
        <div className="box-carro">
          <div className="galeria">
            <Gallery>
              <Swiper
                modules={[Navigation]}
                spaceBetween={50}
                slidesPerView={state}
                navigation
              >
                {carro.fotos.map((foto) => {
                  return (
                    <SwiperSlide>
                      <Item
                        original={foto}
                        thumbnail={foto}
                        width="753"
                        height="560"
                      >
                        {({ ref, open }) => (
                          <img ref={ref} onClick={open} src={foto} />
                        )}
                      </Item>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Gallery>
          </div>

          <div className="first-line">
            <div className="nome-carro">
              <div className="price-div">
                <span className="t1">{carro.nome}</span>
                {
                  isMobile == false && (
                    <div className="prce">R$ {carro.preco}</div>
                  )
                }
              </div>
              <span className="t2">{carro.modelo}</span>

              {
                isMobile && (
                  <div className="prce">
                    <span>R$ {carro.preco}</span>

                    <Button className="btn-simulacao">Simular parcelas <AttachMoney/> </Button>
                  </div>
                )
              }
            </div>

            <div className="options"></div>
          </div>

          <div className="first-line">
            <div className="info-baloon">
              <div className="baloon">
                <CalendarMonthOutlinedIcon />
                <span>{carro.ano}</span>
              </div>

              <div className="baloon">
                <SpeedOutlinedIcon />
                <span>{carro.quilometragem} Km</span>
              </div>

              <div className="baloon">
                <DirectionsCarFilledOutlinedIcon />
                <span>{carro.cambio}</span>
              </div>

              <div className="baloon">
                <LocalGasStationOutlinedIcon />
                <span>{carro.combustivel}</span>
              </div>
            </div>
          </div>

          <strong className=" text-3xl font-semibold pl-10 text-black mt-5 detalhes">
            Detalhes do carro
          </strong>
          <div className="items-start grid grid-cols-2 pl-10 pr-20 pb-4 row gap">
            <div className="flex gap-4 mb-5 items-start flex-col justify-start">
              <div className="grid-over">
                <div className="item-over">
                  <div>
                    <DirectionsCarFilledOutlinedIcon />
                    Carroceria
                  </div>
                  <span>{carro.carroceria}</span>
                </div>

                <div className="item-over">
                  <div>
                    <LocalGasStationOutlinedIcon />
                    <span>Combustivél</span>
                  </div>

                  <span>{carro.combustivel}</span>
                </div>

                <div className="item-over">
                  <div>
                    <SpeedOutlinedIcon />
                    <span>Km</span>
                  </div>

                  <span>{carro.quilometragem}</span>
                </div>

                <div className="item-over">
                  <div>
                    <CalendarMonthOutlinedIcon />
                    <span>Ano</span>
                  </div>

                  <span>{carro.ano}</span>
                </div>

                <div className="item-over">
                  <div>
                    <FormatColorFillOutlinedIcon />
                    <span>Cor</span>
                  </div>

                  <span>{carro.cor}</span>
                </div>

                <div className="item-over">
                  <div>
                    <ArticleIcon />
                    <span>IPVA</span>
                  </div>

                  <span>{carro.ipvaPago}</span>
                </div>
              </div>

              <strong className="text-3xl font-semibold text-black mt-5 detalhes">
                Opcionais
              </strong>

              <div className="grid-over opcionais">
                <div className="item-over">
                  <div>
                    <CheckCircleIcon />
                    Ar condicionado
                  </div>
                </div>

                <div className="item-over">
                  <div>
                    <CheckCircleIcon />
                    <span>Air Bags Passageiro</span>
                  </div>
                </div>

                <div className="item-over">
                  <div>
                    <CheckCircleIcon />
                    <span>Farol de neblina</span>
                  </div>
                </div>

                <div className="item-over">
                  <div>
                    <CheckCircleIcon />
                    <span>Ano</span>
                  </div>
                </div>

                <div className="item-over">
                  <div>
                    <CheckCircleIcon />
                    <span>Cor</span>
                  </div>
                </div>

                <div className="item-over">
                  <div>
                    <CheckCircleIcon />
                    <span>IPVA</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="border pl-10 pt-5 pr-10 rounded-lg w-96 h-96 volvo desktop">
              <div className="flex flex-col items-center justify-center gap-2 mb-10">
                {carro.logo.length > 0 && (
                  <img
                    src={carro.logo}
                    className=" rounded-full w-24 border-2 cursor-pointer border border-green-400"
                  />
                )}
                <h2 className=" font-semibold flex justify-start items-center gap-2 w-full">
                  {carro.vendedor}{" "}
                  {carro.isVerificado && (
                    <CheckCircleIcon className=" text-green-400" />
                  )}
                </h2>
                <h4 className="flex justify-start gap-2 cursor-pointer">
                  {" "}
                  {carro.lojaEndereco}
                  <LocationOnIcon className="text-blue-400" />
                </h4>

                <span>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Pariatur voluptatum neque quas id? Veniam sint voluptatibus
                  repudiandae. Voluptas id nemo facere. Perferendis deserunt
                  iure consequatur magni recusandae error doloribus pariatur.
                </span>
              </div>

              <div
                onClick={() =>
                  window.open(
                    "https://wa.me/55" +
                      carro.contatoTelefone +
                      "?text=Olá gostária de mais informações sobre o " +
                      carro.nome +
                      "    " +
                      window.location.href
                  )
                }
                className=" bg-green-500 gap-2 w-full cursor-pointer hover:bg-green-400 p-2 h-16 rounded-md flex text-center justify-center text-white items-center mb-2 svg-white"
              >
                Envie um WhatsApp
                <CallMadeIcon />
              </div>
            </div>
          </div>

          <div className="border pl-10 pt-5 pr-10 rounded-lg w-full h-96 items-center justify-center mobile">
            <div className="flex flex-col items-center justify-center gap-2 mb-2">
              {carro.logo.length > 0 && (
                <img
                  src={carro.logo}
                  className=" rounded-full w-24 border-2 cursor-pointer border border-green-400"
                />
              )}
              <h2 className=" font-semibold flex justify-start items-center gap-2 w-full">
                {carro.vendedor} <CheckCircleIcon className=" text-green-400" />
              </h2>
              <h4 className="flex justify-start gap-2 cursor-pointer">
                {carro.lojaEndereco}
                <LocationOnIcon className="text-blue-400" />
              </h4>
            </div>

            <span className=" mb-2">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur
              voluptatum neque quas id? Veniam sint voluptatibus repudiandae.
              Voluptas id nemo facere. Perferendis deserunt iure consequatur
              magni recusandae error doloribus pariatur.
            </span>

            <div
              onClick={() =>
                window.open(
                  "https://wa.me/55" +
                    carro.contatoTelefone +
                    "?text=Olá gostária de mais informações sobre o " +
                    carro.nome +
                    "    " +
                    window.location.href
                )
              }
              className=" bg-green-500 gap-2 w-full cursor-pointer hover:bg-green-400 p-2 h-16 rounded-md flex text-center justify-center text-white items-center mb-2 svg-white"
            >
              Envie um WhatsApp
              <CallMadeIcon />
            </div>
          </div>

          <div className="recomendados">
            <span className="title-recomendados">Carros Recomendados</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewCarro;
