import style from "./Banner.module.css";
import "swiper/css";
import "../../Views/ViewCarro/ViewCarro.css";
import "swiper/css/navigation";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { MercadoPagoConfig, Preference } from "mercadopago";
import { initMercadoPago, Wallet } from "@mercadopago/sdk-react";

import { Swiper, SwiperSlide } from "swiper/react";
import { useState } from "react";

initMercadoPago("TEST-e42ddda1-bdec-406b-9cb5-57ca5238bfa4");

// SDK do Mercado Pago
// Adicione as credenciais
const client = new MercadoPagoConfig({
  accessToken:
    "TEST-6290937021580119-042317-0356dab50f3af1195cbacac03259f852-291043249",
});

export default function Banner(props) {
  const [preference, setPreference] = useState();

  async function CriarPagamento() {
    fetch("https://webapicarlab.azurewebsites.net/Pagamento/Criar", {
      method: "POST",
      body: JSON.stringify({
        valor: 10.9,
        usuario: "",
      }),
    }).then(async (resp) => {
      if (resp.status == 200) {
        setPreference(await resp.json());
      }
    });
  }

  async function CriarAnuncio() {
    if (localStorage.getItem("token") != undefined) {
      CriarPagamento();
    } else {
      localStorage.setItem("isCompra", true);
      props.logar();
    }
  }

  return (
    <>
      <div className={style.banner}>
        <span className={style.titulo}>Plano Pantera</span>
        <div className={style.beneficios}>
          <div className={style.beneficio}>
            <span>Anúncio ativo ate a venda do veículo</span>
          </div>

          <div className={style.beneficio}>
            15 Fotos de alta qualidade e definição
          </div>
          <div className={style.beneficio}>
            Menor taxa do mercado para venda{" "}
          </div>
        </div>
        <div className={style.div_price}>
          <div className=" flex items-center">
            <span className={style.price_antigo}>De R$ 60,00</span>
            <span className={style.price}>R$ 1,90</span>
            <span className={style.mes}>/mês</span>
          </div>
          <button onClick={() => CriarPagamento()} className={style.comprar}>
            Criar anúncio <DirectionsCarIcon />{" "}
            <div id="wallet_container">
              {preference && (
                <Wallet
                  initialization={{ preferenceId: preference }}
                  customization={{ texts: { valueProp: "smart_option" } }}
                />
              )}
            </div>
          </button>
        </div>
      </div>
    </>
  );
}
