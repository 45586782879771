import Navbar from "../../componentes/navbar/Navbar";
import { Button, Label, Modal, TextInput } from "flowbite-react";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import "./Login.css";
import { Link, Router } from "react-router-dom";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Checkbox, CheckboxGroup } from "@nextui-org/react";

function Login(props) {
  const [openModal, setOpenModal] = useState(false);
  const [registrar, setRegistrar] = useState(props.registrar);
  const [email, setEmail] = useState();
  const [nome, setNome] = useState();
  const [cep, setCep] = useState();
  const [senha, setSenha] = useState();
  const [telefone, setTelefone] = useState();
  const [isLoja, setIsLoja] = useState(false);

  const [repeatSenha, setRepeatSenha] = useState();

  const notify = () => toast("Verifique seu email e senha.");

  async function autenticar(resp) {
    if (resp.status == 200) {
      var resultado = await resp.json();
      localStorage.setItem("token", resultado.token);
      if (localStorage.getItem("isCompra")) {
        localStorage.removeItem("isCompra");
        window.location.href = "/vender";
      } else {
        window.location.href = "/dashboard";
      }
    } else {
      toast.error("Verifique seu email e senha", {
        duration: 4000,
      });
    }
  }

  async function CriarConta() {
    fetch("https://webapicarlab.azurewebsites.net/Usuario", {
      method: "POST",
      body: JSON.stringify({
        nome: nome,
        endereco: cep,
        email: email,
        senha: senha,
        isLoja: isLoja,
      }),
    });
  }

  function Logar() {
    console.log(email);
    fetch("https://webapicarlab.azurewebsites.net/Usuario/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        senha: senha,
      }),
    }).then((response) => autenticar(response));
  }
  return (
    <div className="central">
      <Toaster />
      <Navbar />

      <div className="login">
        {registrar ? (
          <form className="flex max-w-md flex-col gap-4 div-login overflow-y-scroll">
            <div className="flex items-center justify-start gap-5 ">
              <span
                onClick={() => setRegistrar(true)}
                className=" font-semibold cursor-pointer span-lg"
                style={{ color: "#405ff2" }}
              >
                Criar conta
              </span>
              <span
                onClick={() => setRegistrar(false)}
                className=" font-semibold cursor-pointer span-rg"
              >
                Entrar
              </span>
            </div>
            <div className="w-full">
              <div className="mb-2 block">
                <Label htmlFor="email2" value="Nome" />
              </div>
              <TextInput
                id="email2"
                type="email"
                placeholder="Informe seu nome ou da sua loja"
                required
                shadow
                onChange={(x) => setNome(x.target.value)}
              />
            </div>

            <div className="w-full gap-3">
              <div className="mb-2 flex flex-col items-start justify-start gap-3">
                <Label htmlFor="email2" value="É um perfil de loja ?" />
              </div>

              <div className=" gap-5 flex w-36">
                <CheckboxGroup
                  orientation="horizontal"
                  defaultValue={["buenos-aires", "san-francisco"]}
                >
                  <Checkbox onClick={() => setIsLoja(true)} value="Sim">Sim</Checkbox>
                  <Checkbox onClick={() => setIsLoja(false)} value="Não">Não</Checkbox>
                </CheckboxGroup>
              </div>
            </div>

            <div className="w-full">
              <div className="mb-2 block">
                <Label htmlFor="email2" value="CEP" />
              </div>
              <TextInput
                id="email2"
                type="email"
                placeholder="Qual seu cep ?"
                required
                shadow
                onChange={(x) => setCep(x.target.value)}
              />
            </div>

            <div className="w-full">
              <div className="mb-2 block">
                <Label htmlFor="email2" value="Email" />
              </div>
              <TextInput
                id="email2"
                type="email"
                placeholder="seuemail@email.com"
                required
                shadow
                onChange={(x) => setEmail(x.target.value)}
              />
            </div>
            <div className="w-full">
              <div className="mb-2 block">
                <Label htmlFor="password2" value="Senha" />
              </div>
              <TextInput
                onChange={(x) => setSenha(x.target.value)}
                id="password2"
                type="password"
                required
                shadow
              />
            </div>
            <div className="w-full">
              <div className="mb-2 block">
                <Label htmlFor="repeat-password" value="Repita a senha" />
              </div>
              <TextInput
                onChange={(x) => setRepeatSenha(x.target.value)}
                id="repeat-password"
                type="password"
                required
                shadow
              />
            </div>
            <div className="flex items-center gap-2">
              <Checkbox required id="agree" style={{ color: "#405ff2" }} />
              <Label htmlFor="agree" className="flex">
                Estou de acordo &nbsp;
                <Link
                  onClick={() => setOpenModal(true)}
                  href="#"
                  style={{ color: "#405ff2" }}
                  className="hover:underline"
                >
                  termos e condições
                </Link>
              </Label>
            </div>
            <Button
              onSubmit={() => CriarConta()}
              style={{ backgroundColor: "#405ff2" }}
              type="submit"
            >
              Criar conta
            </Button>
          </form>
        ) : (
          <form className="flex max-w-md flex-col gap-4 div-login">
            <div className="flex items-center justify-start gap-5">
              <span
                onClick={() => setRegistrar(true)}
                className=" font-semibold cursor-pointer span-lg"
              >
                Criar conta
              </span>
              <span
                onClick={() => setRegistrar(false)}
                className=" font-semibold cursor-pointer span-rg"
                style={{ color: "#405ff2" }}
              >
                Entrar
              </span>
            </div>
            <div className="w-full">
              <div className="mb-2 block">
                <Label htmlFor="email2" value="Email" />
              </div>
              <TextInput
                id="email2"
                type="email"
                placeholder="seuemail@email.com"
                required
                shadow
                onChange={(x) => setEmail(x.target.value)}
              />
            </div>
            <div className="w-full">
              <div className="mb-2 block">
                <Label htmlFor="password2" value="Senha" />
              </div>
              <TextInput
                onChange={(x) => setSenha(x.target.value)}
                id="password2"
                type="password"
                required
                shadow
              />
            </div>
            <div className="flex items-center gap-2">
              <Checkbox required id="agree" style={{ color: "#405ff2" }} />
              <Label htmlFor="agree" className="flex">
                Manter conectado &nbsp;
                <Link
                  onClick={() => setOpenModal(true)}
                  href="#"
                  style={{ color: "#405ff2" }}
                  className="hover:underline"
                >
                  Esqueci a senha
                </Link>
              </Label>
            </div>
            <Button
              style={{ backgroundColor: "#405ff2" }}
              className="svg-white"
              onClick={(x) => Logar()}
            >
              Entrar <ArrowOutwardIcon />
            </Button>
          </form>
        )}
      </div>

      <Modal show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header>Termos e condições</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              With less than a month to go before the European Union enacts new
              consumer privacy laws for its citizens, companies around the world
              are updating their terms of service agreements to comply.
            </p>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              The European Union’s General Data Protection Regulation (G.D.P.R.)
              goes into effect on May 25 and is meant to ensure a common set of
              data rights in the European Union. It requires organizations to
              notify users as soon as possible of high-risk data breaches that
              could personally affect them.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ backgroundColor: "#405ff2" }}
            onClick={() => setOpenModal(false)}
          >
            Eu aceito
          </Button>
          <Button color="gray" onClick={() => setOpenModal(false)}>
            Não aceito
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Login;
