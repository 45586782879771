import {
  Button,
  Dropdown,
  DropdownItem,
  FileInput,
  Label,
  Table,
  TextInput,
} from "flowbite-react";
import Navbar from "../../componentes/navbar/Navbar";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import HomeIcon from "@mui/icons-material/Home";
import TimeToLeaveOutlinedIcon from "@mui/icons-material/TimeToLeaveOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CloseIcon from "@mui/icons-material/Close";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import "./CarroTab.css";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Item } from "react-photoswipe-gallery";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { UploadButton } from "@bytescale/upload-widget-react";

const options = {
  apiKey: "public_kW15byt7ubthty3FoTvqho5eFspq", // This is your API key.
  maxFileCount: 1,
  locale: "PT-BR",
};

var dateTime = new Date();

function CarroTab() {
  function GetYears() {
    var year = dateTime.getFullYear();
    var list = [year];

    for (let index = 0; index < 30; index++) {
      list.push(year--);
    }

    return list;
  }

  function GetModelos(marca) {
    setMarca(marca.name);
    fetch(
      "https://parallelum.com.br/fipe/api/v2/cars/brands/" +
        marca.code +
        "/models"
    )
      .then((response) => response.json())
      .then((resultado) => setModelos(resultado))
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function removerFoto(x) {
    var fotoList = fotos.indexOf(x);
    var array = fotos.splice(fotoList + 1);
    setFotos(array);
  }
  function UploadImagem() {
    var newList = fotos;
    console.log(newList);
    newList.push(foto);
    setFotos(newList);
    setFoto("");
  }

  function CriarCarro() {
    fetch("https://webapicarlab.azurewebsites.net/Carro/criar-carro", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        placa: placaCompleta,
        placaFinal: finalPlaca,
        nome: nome,
        marca: marca,
        modelo: modelo,
        ano: ano.toString(),
        quilometragem: quilometragem,
        cor: cor,
        fotos: fotos,
        destaque: "",
        preco: preco,
        contatoTelefone: contato,
        emailContato: emailContato,
        categoria: categoria,
        cambio: cambio,
        logo: logo,
        lojaEndereco: endereco,
        Combustivel: combusitvel,
        vendedor: vendedor,
        carroceria: carroceria,
        ipvaPago: ivpaPago,
        licenciado: licenciado,
        aceitaTroca: aceitaTroca,
        unicoDono: unicoDono,
        lowprice: false,
        lowkm: false,
        isVerificado: "",
        relacao: relacao,
      }),
    })
      .then((response) => response.json())
      .then((resultado) => localStorage.setItem("res", resultado))
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  const [opt, setOptions] = useState({
    anos: GetYears(),
    combusitveis: ["Gasolina", "Flex", "Álcool", "Elétrico", "Híbrido"],
    cores: [
      "Preto",
      "Prata",
      "Cinza",
      "Azul",
      "Branco",
      "Vermelho",
      "Verde",
      "Vinho",
    ],
    basicos: ["Sim", "Não"],
    carrocerias: ["SUV", "Sedan", "Coupe", "Hatch", "Caminhonete", "Pickup"],
    cambios: ["Manual", "Automático"],
    placas: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
  });
  const [carros, setCarros] = useState([]);
  const [isCadastrar, setCadastrar] = useState(false);
  const [etapa, setEtapa] = useState(0);
  const [modelos, setModelos] = useState([]);
  const [marca, setMarca] = useState("Marca");
  const [modelo, setModelo] = useState("Modelo");
  const [quilometragem, setQuilometragem] = useState("Quilometragem");
  const [preco, setPreco] = useState("Preco");
  const [aceitaTroca, setTroca] = useState("Aceita Troca");
  const [ivpaPago, setIpvaPago] = useState("IPVA Pago");
  const [combusitvel, setConmbustivel] = useState("Combustivél");
  const [logo, setLogo] = useState("Combustivél");
  const [vendedor, setVendedor] = useState("Combustivél");
  const [cor, setCor] = useState("Cor");
  const [cambio, setCambio] = useState("Câmbio");
  const [unicoDono, setUnicoDono] = useState("Único dono");
  const [ano, setAno] = useState("Ano");
  const [finalPlaca, setPlaca] = useState("Final da placa");
  const [placaCompleta, setPlacaCompleta] = useState("Placa");
  const [licenciado, setLicenciado] = useState("Licenciado");
  const [carroceria, setCarroceria] = useState("Carroceria");
  const [fotos, setFotos] = useState([]);
  const [foto, setFoto] = useState("");
  const [nome, setNome] = useState("");
  const [contato, setContato] = useState("");
  const [emailContato, setEmailContato] = useState("");
  const [endereco, setEndereco] = useState("");
  const [categoria, setCategoria] = useState("");
  const [relacao, setRelacao] = useState("");

  const [marcas, setMarcas] = useState();

  const [carro, setCarro] = useState({
    marca: "Marca",
    modelo: "Modelo",
    quilometragem: "Quilometragem",
    preco: "Preço",
    aceitaTroca: "Aceita troca",
    ivpaPago: "IPVA Pago",
    combusitvel: "Combustivél",
    cor: "Cor",
    cambio: "Câmbio",
    unicoDono: "Único Dono",
    ano: ano,
    carroceria: "Carroceria",
    finalPlaca: "Final da placa",
    licenciado: "Licenciado",
    relacao: "",
  });

  useEffect(() => {
    fetch("https://parallelum.com.br/fipe/api/v2/cars/brands")
      .then((response) => response.json())
      .then((resultado) => setMarcas(resultado))
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  return (
    <div className=" bg-white rounded flex flex-col gap-10 items-start justify-start p-16 carros-central">
      <span className=" font-semibold text-5xl span-tt mt-2">Seus carros</span>
      <Button
        style={{ backgroundColor: "#405ff2" }}
        className=" w-44 svg-white"
        onClick={() => setCadastrar(!isCadastrar)}
      >
        {isCadastrar ? <span>Seus carros</span> : <span>Adicionar carro</span>}
        <TimeToLeaveOutlinedIcon />{" "}
      </Button>

      {isCadastrar ? (
        etapa == 0 ? (
          <div className=" bg-white shadow-xl border border-cyan-200 w-full rounded-md grid grid-cols-3 gap-10 p-10 h-full lista-config">
            <div className="flex drop-cadastro">
              <Dropdown
                label={marca}
                inline
                className=" bg-white h-48 overflow-y-scroll"
              >
                {" "}
                {marcas.length > 0 &&
                  marcas.map((marcaprop) => {
                    return (
                      <DropdownItem onClick={() => GetModelos(marcaprop)}>
                        {marcaprop.name}
                      </DropdownItem>
                    );
                  })}
              </Dropdown>
            </div>

            <div className="flex drop-cadastro">
              <Dropdown
                label={modelo}
                inline
                className=" bg-white h-48 overflow-y-scroll"
              >
                {" "}
                {modelos.length > 0 &&
                  modelos.map((modelo) => {
                    return (
                      <DropdownItem onClick={() => setModelo(modelo.name)}>
                        {modelo.name}
                      </DropdownItem>
                    );
                  })}
              </Dropdown>
            </div>

            <div className="flex drop-cadastro">
              <Dropdown
                label={ano}
                inline
                className=" bg-white h-48 overflow-y-scroll"
              >
                {" "}
                {opt.anos.map((x) => {
                  return (
                    <Dropdown.Item onClick={() => setAno(x)}>{x}</Dropdown.Item>
                  );
                })}
              </Dropdown>
            </div>

            <div className="flex drop-cadastro">
              <Dropdown
                label={cor}
                inline
                className=" bg-white h-48 overflow-y-scroll"
              >
                {" "}
                {opt.cores.map((x) => {
                  return (
                    <Dropdown.Item onClick={() => setCor(x)}>{x}</Dropdown.Item>
                  );
                })}
              </Dropdown>
            </div>

            <div className="flex drop-cadastro">
              <Dropdown
                label={ivpaPago}
                inline
                className=" bg-white h-48 overflow-y-scroll"
              >
                {" "}
                {opt.basicos.map((x) => {
                  return (
                    <Dropdown.Item onClick={() => setIpvaPago(x)}>
                      {x}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown>
            </div>

            <div className="flex drop-cadastro">
              <Dropdown
                label={finalPlaca}
                inline
                className=" bg-white overflow-y-scroll h-48"
              >
                {" "}
                {opt.placas.map((x) => {
                  return (
                    <Dropdown.Item onClick={() => setPlaca(x)}>
                      {x}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown>
            </div>

            <div className="flex drop-cadastro">
              <Dropdown
                label={cambio}
                inline
                className=" bg-white h-48 overflow-y-scroll"
              >
                {" "}
                {opt.cambios.map((x) => {
                  return (
                    <Dropdown.Item onClick={() => setCambio(x)}>
                      {x}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown>
            </div>

            <div className="flex drop-cadastro">
              <Dropdown
                label={aceitaTroca}
                inline
                className=" bg-white h-48 overflow-y-scroll"
              >
                {" "}
                {opt.basicos.map((x) => {
                  return (
                    <Dropdown.Item onClick={() => setTroca(x)}>
                      {x}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown>
            </div>

            <div className="flex drop-cadastro">
              <Dropdown
                label={combusitvel}
                inline
                className=" bg-white h-48 overflow-y-scroll"
              >
                {" "}
                {opt.combusitveis.map((x) => {
                  return (
                    <Dropdown.Item onClick={() => setConmbustivel(x)}>
                      {x}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown>
            </div>

            <div className="flex drop-cadastro">
              <Dropdown
                label={carroceria}
                inline
                className=" bg-white h-48 overflow-y-scroll"
              >
                {" "}
                {opt.carrocerias.map((x) => {
                  return (
                    <Dropdown.Item onClick={() => setCarroceria(x)}>
                      {x}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown>
            </div>

            <div className="flex drop-cadastro">
              <Dropdown
                label={licenciado}
                inline
                className=" bg-white h-48 overflow-y-scroll"
              >
                {" "}
                {opt.basicos.map((x) => {
                  return (
                    <Dropdown.Item onClick={() => setLicenciado(x)}>
                      {x}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown>
            </div>

            <div className="flex drop-cadastro">
              <Dropdown
                label={unicoDono}
                inline
                className=" bg-white h-48 overflow-y-scroll"
              >
                {" "}
                {opt.basicos.map((x) => {
                  return (
                    <Dropdown.Item onClick={() => setUnicoDono(x)}>
                      {x}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown>
            </div>

            <div className="w-full">
              <div className="mb-2 block">
                <Label htmlFor="password2" value="Quilometragem" />
              </div>
              <TextInput
                placeholder="Exemplo : 49.000 Km"
                onChange={(x) => setQuilometragem(x.target.value)}
                id="password2"
                type="text"
                required
                shadow
              />
            </div>

            <div className="w-full">
              <div className="mb-2 block">
                <Label htmlFor="password2" value="Preço" />
              </div>
              <TextInput
                placeholder="Exemplo : R$ 49.000"
                onChange={(x) => setPreco(x.target.value)}
                id="password2"
                type="text"
                required
                shadow
              />
            </div>

            <div className="w-full">
              <div className="mb-2 block">
                <Label htmlFor="password2" value="Endereço para visita" />
              </div>
              <TextInput
                placeholder="Exemplo : Rua Boa vista, 459 Parque Horizonte"
                onChange={(x) => setEndereco(x.target.value)}
                id="password2"
                type="text"
                required
                shadow
              />
            </div>

            <div className="w-full">
              <div className="mb-2 block">
                <Label htmlFor="password2" value="Link relacionado" />
              </div>
              <TextInput
                placeholder="Link Relacionado"
                onChange={(x) => setRelacao(x.target.value)}
                id="password2"
                type="text"
                required
                shadow
              />
            </div>

            <div className="w-full">
              <div className="mb-2 block">
                <Label htmlFor="password2" value="Email para contato" />
              </div>
              <TextInput
                placeholder="Exemplo : admin@gmail.com"
                onChange={(x) => setEmailContato(x.target.value)}
                id="password2"
                type="text"
                required
                shadow
              />
            </div>

            <div className="w-full">
              <div className="mb-2 block">
                <Label htmlFor="password2" value="Telefone para contato" />
              </div>
              <TextInput
                placeholder="Exemplo : (23)9966035498"
                onChange={(x) => setContato(x.target.value)}
                id="password2"
                type="text"
                required
                shadow
              />
            </div>

            <div className="w-full">
              <div className="mb-2 block">
                <Label htmlFor="password2" value="Placa completa" />
              </div>
              <TextInput
                onChange={(x) => setPlacaCompleta(x.target.value)}
                id="password2"
                type="text"
                placeholder="Exemplo : EZD-5978"
                required
                shadow
              />
            </div>

            <div className="w-full">
              <div className="mb-2 block">
                <Label htmlFor="password2" value={"URL Fotos"} />
              </div>
              <TextInput
                placeholder="Url das fotos do carro"
                value={foto}
                onChange={(x) => setFoto(x.target.value)}
                id="password2"
                type="text"
                required
                shadow
              />
              <Button
                className="mt-5"
                style={{ backgroundColor: "#405ff2" }}
                onClick={(x) => UploadImagem()}
              >
                Adicionar foto
              </Button>
            </div>

            <div className="w-full grid grid-cols-2 gap-3 overflow-y-scroll max-h-96">
              {fotos.map((x, index) => {
                if (x.length != undefined) {
                  return (
                    <div>
                      {index == 0 && (
                        <div
                          style={{
                            backgroundColor: "#405ff2",
                            color: "white",
                          }}
                          className="mt-1 items-start justify-start absolute p-1 ml-10 bg-blue rounded"
                        >
                          Principal
                        </div>
                      )}
                      <CloseIcon
                        onClick={() => removerFoto(x)}
                        className=" mt-1 ml-1 absolute cursor-pointer bg-gray-600 rounded-lg svg-white"
                      />
                      <img className=" w-60 rounded" src={x} />
                    </div>
                  );
                }
              })}
            </div>

            <div className="w-full">
              <div className="mb-2 block">
                <Label htmlFor="password2" value="Nome vendedor" />
              </div>
              <TextInput
                placeholder="Exemplo : GR Motors"
                onChange={(x) => setVendedor(x.target.value)}
                id="password2"
                type="text"
                required
                shadow
              />
            </div>
            <div className="w-full">
              <div className="mb-2 block">
                <Label htmlFor="email2" value="Logo Url" />
              </div>
              <TextInput
                onChange={(x) => setLogo(x.target.value)}
                id="email2"
                type="email"
                placeholder="URL Logo"
                required
                shadow
              />
            </div>
            <div className="w-full">
              <div className="mb-2 block">
                <Label htmlFor="email2" value="Nome anúncio" />
              </div>
              <TextInput
                onChange={(x) => setNome(x.target.value)}
                id="email2"
                type="email"
                placeholder="Exemplo : Chevrolet Prisma"
                required
                shadow
              />
            </div>
            <div className="w-full"> </div>

            <div className="w-full">
              <Button color="gray">Voltar</Button>
            </div>

            <div className="w-full">
              <Button
                onClick={() => setEtapa(1)}
                style={{ backgroundColor: "#405ff2" }}
              >
                Próxima etapa - Fotos
              </Button>
            </div>
          </div>
        ) : (
          <div className=" bg-white shadow-xl border border-cyan-200 w-full rounded-md grid grid-cols-1 gap-10 p-10 h-full">
            <div className="flex w-full items-center justify-center">
              <Label
                htmlFor="dropzone-file"
                className="dark:hover:bg-bray-800 flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div className="flex flex-col items-center justify-center pb-6 pt-5">
                  <svg
                    className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLineJoin="round"
                      strokeWidth="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <UploadButton
                    options={options}
                    onComplete={(files) =>
                      alert(files.map((x) => x.fileUrl).join("\n"))
                    }
                  >
                    {({ onClick }) => (
                      <button onClick={onClick}>Enviar foto</button>
                    )}
                  </UploadButton>

                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    SVG, PNG, JPG or GIF (MAX. 800x400px)
                  </p>
                </div>
              </Label>
            </div>

            <div className="w-full grid grid-cols-2 gap-10">
              <div className="w-full">
                <Button onClick={() => setEtapa(0)} color="gray">
                  Voltar - Detalhes do carro
                </Button>
              </div>

              <div className="w-full">
                <Button
                  onClick={() => CriarCarro()}
                  style={{ backgroundColor: "#405ff2" }}
                >
                  Criar anúnio
                </Button>
              </div>
            </div>
          </div>
        )
      ) : (
        <div className="w-full desktop overflow-y-scroll">
          <Table>
            <Table.Head className=" ">
              <Table.HeadCell>Foto Principal</Table.HeadCell>
              <Table.HeadCell>Nome</Table.HeadCell>
              <Table.HeadCell>Expira em</Table.HeadCell>
              <Table.HeadCell>Visualizações</Table.HeadCell>
              <Table.HeadCell>Preço</Table.HeadCell>
              <Table.HeadCell>
                <span className="sr-only">Editar</span>
              </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {carros.length > 0 &&
                carros.map((carro) => {
                  return (
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        <img
                          className="rounded-xl"
                          width={"120px"}
                          src={carro.fotos[0]}
                        />
                      </Table.Cell>
                      <Table.Cell>{carro.nome}</Table.Cell>
                      <Table.Cell>15/02/2024</Table.Cell>
                      <Table.Cell>150</Table.Cell>
                      <Table.Cell>R$ {carro.preco}</Table.Cell>
                      <Table.Cell>
                        <a
                          href="#"
                          style={{ color: "#405ff2" }}
                          className="font-medium underline"
                        >
                          Editar
                        </a>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </div>
      )}
    </div>
  );
}

export default CarroTab;
