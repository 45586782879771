import "./Navbar.css";
import "../../../src/index.css";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Link } from "react-router-dom";
import { Dropdown, DropdownItem } from "flowbite-react";
function Navbar(props) {
  return (
    <div className="header">
      <Link to={"/home"}>
        <div className="item">
          <img width="120" src="/logo.png" alt="error" />
        </div>
      </Link>
      <div className="item desktop"></div>
      <div className="item desktop">
        <Link to={"/login"}>
          <span>{ localStorage.getItem("token") != null ? "Dashboard" : "Entrar"}</span>
        </Link>
        <Link to={"/carro"}>
          <span>Estoque</span>
        </Link>

        <Link target="_blank" to={"https://wa.link/fqwt7f"}>
          <span>Contato</span>
        </Link>

        <Link to={"/vender"}>
          <span>Vender</span>
        </Link>
      </div>

      <div className="item mobile">
        <Dropdown renderTrigger={() => <DensityMediumIcon />}>
          <Link to={"/home"}>
            <DropdownItem>Home</DropdownItem>
          </Link>

          <Link to={"/login"}>
            <DropdownItem>Entrar</DropdownItem>
          </Link>

          <Link to={"/carro"}>
            <DropdownItem>Estoque</DropdownItem>
          </Link>

          <Link target="_blank" to={"https://wa.link/fqwt7f"}>
            <DropdownItem>Contato</DropdownItem>
          </Link>

          <Link to={"/vender"}>
            <DropdownItem>Vender</DropdownItem>
          </Link>
        </Dropdown>
      </div>
    </div>
  );
}

export default Navbar;
