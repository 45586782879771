import { useEffect, useState } from "react";
import "./Marcas.css";
import { Spinner } from "flowbite-react";
import { Link } from "react-router-dom";
function Marcas() {
  function salvarMarca(marca){
    localStorage.setItem("marcaname", marca.nome) 
    localStorage.setItem("marcaId", marca.id)
    localStorage.setItem("modeloname", "Qualquer modelo")
    localStorage.setItem("condicao", "Usado e novo")
    localStorage.setItem("modeloId", 0)
  }
  const [marcas, setMarcas] = useState([{}]);
  useEffect(() => {
    fetch("https://webapicarlab.azurewebsites.net/Marca")
      .then((response) => response.json())
      .then((resultado) => setMarcas(resultado))
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);
  return (
    <div className="marcas-div">
      <div className="header-marcas">
        <span>Procure com base nas melhores marcas</span>
      </div>

      {marcas.length > 1 ? (
        <div className="lista-marcas">
          {marcas.map((marca) => {
            return (
              <Link to={"/carro"} onClick={(x) => salvarMarca(marca)}>
                <div className="marca">
                  <img src={marca.fotoUrl} />
                </div>
              </Link>
            );
          })}
        </div>
      ) : (
        <div className="w-full flex items-center justify-center">
          <Spinner
            aria-label="Extra large spinner example Center-aligned w-full"
            size="xl"
          />
        </div>
      )}
    </div>
  );
}

export default Marcas;
