import style from "./venda.module.css";
import { Input } from "@nextui-org/react";
import SearchIcon from "@mui/icons-material/Search";
import { Button, ButtonGroup } from "@nextui-org/react";
import { NextUIProvider } from "@nextui-org/react";
import CheckIcon from "@mui/icons-material/Check";
import { useState } from "react";
import { UploadButton } from "@bytescale/upload-widget-react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { CheckboxGroup, Checkbox } from "@nextui-org/react";
import CloseIcon from "@mui/icons-material/Close";
import { Skeleton } from "@nextui-org/react";
import toast, { Toaster } from "react-hot-toast";
import { Gallery, Item } from "react-photoswipe-gallery";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

export default function VendaEtapa() {
  const [fotos, setFotos] = useState([]);

  const options = {
    apiKey: "public_kW15byt7ubthty3FoTvqho5eFspq", // This is your API key.
    maxFileCount: 15,
  };

  const [load, setLoad] = useState(false);
  const [isvalid, setIsvalid] = useState(true);
  const [etapa, setEtapa] = useState(0);
  const [opcionais, setOpcionais] = useState();
  const [aceitaTroca, setTroca] = useState(false);
  const [ipvaPago, setIpva] = useState(false);
  const [placa, setPlaca] = useState();
  const [preco, setPreco] = useState("");
  const [quilometragem, setQuilometragem] = useState("");
  const [cep, setCep] = useState();
  const [dadosCep, setDadosCep] = useState({
    logradouro: "",
    bairro: "",
    uf: "",
    localidade: "",
  });
  const [dadosplaca, setDadosPlaca] = useState({
    placa: "",
    cor: "",
    fipe: "",
    ano: "",
    marca: "",
    modelo: "",
    categoria: "",
    combustivel: "",
    restricao: "",
  });
  async function addFoto(fotoUrl) {
    setFotos([...fotos, fotoUrl]);
  }

  async function removeFoto(fotoUrl) {
    let array = fotos.filter((x) => x != fotoUrl);
    setFotos(array);
  }

  function GetPlaca(placa) {
    setLoad(true);
    fetch("https://webapicarlab.azurewebsites.net/Placa", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(placa),
    }).then((res) => validarPlaca(res));
  }

  async function validarPlaca(res) {
    if (res.status == 200) {
      var json = await res.json();
      setDadosPlaca(json);
      setLoad(false);
    } else {
      consultaPlaca(placa);
    }
  }

  function consultaPlaca(placa) {
    fetch(
      `https://wdapi2.com.br/consulta/${placa}/98bfd716d860b4ed88c1d408c95f8571`
    )
      .then((result) => result.json())
      .then((json) => preencherPlaca(json));
  }

  function preencherPlaca(result) {
    setDadosPlaca(result);
    fetch("https://webapicarlab.azurewebsites.net/Placa/cadastrar", {
      method: "POST",
      body: JSON.stringify({
        placa: result.placa,
        cor: result.cor,
        nome: result.marcaModelo,
        ano: result.ano,
        marca: result.marca,
        modelo: result.modelo,
        categoria: result.extra.categoria || "  ",
        combustivel: result.extra.combustivel,
        restricao: result.situacao,
        cambio: result.extra.segmento,
      }),
    });
  }

  function mascaraFinanceira(valor) {
    var valorP = valor.replaceAll(".", "");
    var valorEspace = valorP.replaceAll("R$", "");
    var valorS = valorEspace.replaceAll(" ", "");
    let precoFinal = "";
    let inicio = "";
    let inicio2 = "";
    let final = "";

    if (valorS.length > 5) {
      inicio = valorS.substring(0, 3);
      final = valorS.substring(2, valorS.length - 1);
    }

    if (valorS.length > 6) {
      inicio = valorS.substring(0, 1);
      inicio2 = valorS.substring(1, 4);
      final = valorS.substring(4, valorS.length);
    }

    if (valorS.length == 5) {
      inicio = valorS.substring(0, 2);
      final = valorS.substring(2, valorS.length);
    }

    if (valorS.length > 2 && valorS.length <= 4) {
      inicio = valorS.substring(0, 1);
      final = valorS.substring(1, valorS.length);
    }

    if (valorS.length > 0 && valorS.length <= 2) {
      inicio = valorS;
      final = "";
    }

    if (valorS.length > 2 || valorS.length > 5) {
      precoFinal = `${inicio}.${final}`;
    }

    if (valorS.length > 0 && valorS.length <= 3) {
      precoFinal = `${inicio}${final}`;
    }

    if (valorS.length > 3 || valorS.length > 5) {
      precoFinal = `${inicio}.${final}`;
    }

    if (valorS.length > 6) {
      precoFinal = `${inicio}.${inicio2}.${final}`;
    }

    setPreco(precoFinal);
  }

  function mascaraKm(valor) {
    var valorP = valor.replaceAll(".", "");
    var valorEspace = valorP.replaceAll("Km", "");
    var valorS = valorEspace.replaceAll(" ", "");
    let precoFinal = "";
    let inicio = "";
    let inicio2 = "";
    let final = "";

    if (valorS.length > 5) {
      inicio = valorS.substring(0, 3);
      final = valorS.substring(2, valorS.length - 1);
    }

    if (valorS.length > 6) {
      inicio = valorS.substring(0, 1);
      inicio2 = valorS.substring(1, 4);
      final = valorS.substring(4, valorS.length);
    }

    if (valorS.length == 5) {
      inicio = valorS.substring(0, 2);
      final = valorS.substring(2, valorS.length);
    }

    if (valorS.length > 2 && valorS.length <= 4) {
      inicio = valorS.substring(0, 1);
      final = valorS.substring(1, valorS.length);
    }

    if (valorS.length > 0 && valorS.length <= 2) {
      inicio = valorS;
      final = "";
    }

    if (valorS.length > 2 || valorS.length > 5) {
      precoFinal = `${inicio}.${final}`;
    }

    if (valorS.length > 0 && valorS.length <= 3) {
      precoFinal = `${inicio}${final}`;
    }

    if (valorS.length > 3 || valorS.length > 5) {
      precoFinal = `${inicio}.${final}`;
    }

    if (valorS.length > 6) {
      precoFinal = `${inicio}.${inicio2}.${final}`;
    }

    setQuilometragem(precoFinal);
  }

  function consultaCep(cep) {
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((result) => validatorCep(result))
  }

  async function CriarAnuncio(){
    fetch("", {method : 'POST', body : JSON.stringify({
      placa : placa,
      preco : preco,
      fotos : fotos,
      cep : cep,
      nomeAnuncio : dadosplaca.marcaModelo,
      opcionais : await opcionais.filter(x => x.selecionado == true)
    })})
  }

  async function validatorCep(json){
    if(json.status == 200){
      var result = await json.json();
      setDadosCep(result);
    } else {
      toast.error("Informe um cep válido");
    }
  }

  function validator(etapaValidator) {
    if (etapaValidator == 0) {
      if (placa != null && placa != undefined && placa.length > 2 && dadosplaca.marca.length > 0) {
        setEtapa(etapa + 1);
        return true;
      } else {
        toast.error("Verifique a placa informada e clique na lupa novamente");
      }
    }

    if (etapaValidator == 1) {
      if (cep != null && dadosCep.logradouro != undefined && cep.length > 2 && dadosCep.logradouro.length > 0) {
        setEtapa(etapa + 1);
        return true;
      } else {
        toast.error("Verifique o cep informado e clique na lupa novamente");
      }
    }

    if (etapaValidator == 2) {
      if (preco != null && preco.length > 3) {
        setEtapa(etapa + 1);
        return true;
      } else {
        toast.error("Informe um preço válido");
      }
    }

    if (etapaValidator == 3) {
      if (quilometragem != null && quilometragem.length > 0) {
        setEtapa(etapa + 1);
        return true;
      } else {
        toast.error("Informe um km válido");
      }
    }

    if (etapaValidator == 4) {
      if (fotos != null && fotos.length > 0) {
        setEtapa(etapa + 1);
        return true;
      } else {
        toast.error("Insira pelo menos 3 fotos");
      }
    }
  }

  return (
    <NextUIProvider>
      <Toaster />
      <div className={style.container}>
        {etapa == 0 && (
          <>
            <Input
              required={true}
              variant="bordered"
              endContent={
                <div onClick={() => GetPlaca(placa)}>
                  <SearchIcon />
                </div>
              }
              labelPlacement="inside"
              description="Essa informação não será mostrada em seu anúncio"
              placeholder="Insira a placa"
              label="Placa"
              className={style.input}
              value={placa}
              maxLength={8}
              onChange={(x) => setPlaca(x.target.value)}
            />
            <div className=" overflow-y-scroll p-3 gap-2 flex flex-col h-48">
              <div className="ske">
                <Skeleton isLoaded={!load} className={style.Skeleton}>
                  <Input
                    disabled
                    placeholder="Marca"
                    label="Marca"
                    className={style.input}
                    value={dadosplaca.marca}
                  />
                </Skeleton>
              </div>

              <div className="ske">
                <Skeleton isLoaded={!load} className={style.Skeleton}>
                  <Input
                    disabled
                    placeholder="Modelo"
                    label="Modelo"
                    className={style.input}
                    value={dadosplaca.modelo}
                  />
                </Skeleton>
              </div>

              <div className="ske">
                <Skeleton isLoaded={!load} className={style.Skeleton}>
                  <Input
                    disabled
                    placeholder="Cor"
                    label="Cor"
                    className={style.input}
                    value={dadosplaca.cor}
                  />
                </Skeleton>
              </div>

              <div className="ske">
                <Skeleton isLoaded={!load} className={style.Skeleton}>
                  <Input
                    disabled
                    placeholder="Ano"
                    label="Ano"
                    className={style.input}
                    value={dadosplaca.ano}
                  />
                </Skeleton>
              </div>

              <div className="ske">
                <Skeleton isLoaded={!load} className={style.Skeleton}>
                  <Input
                    disabled
                    placeholder="Câmbio"
                    label="Câmbio"
                    className={style.input}
                    value={dadosplaca.cambio}
                  />
                </Skeleton>
              </div>

              <div className="ske">
                <Skeleton isLoaded={!load} className={style.Skeleton}>
                  <Input
                    disabled
                    placeholder="Restrição"
                    label="Restrição"
                    className={style.input}
                    value={dadosplaca.restricao}
                  />
                </Skeleton>
              </div>
            </div>
          </>
        )}

        {etapa == 1 && (
          <>
            <Input
              variant="bordered"
              endContent={
                <div onClick={() => consultaCep(cep)}>
                  <SearchIcon />
                </div>
              }
              labelPlacement="inside"
              description="Informe seu cep com apenas números, para que o anúncio apareça para as pessoas certas"
              placeholder="Exemplo : 12225650"
              label="CEP"
              className={style.input}
              maxLength={8}
              value={cep}
              onChange={(x) => setCep(x.target.value)}
            />
            <Input
              disabled
              placeholder="Estado"
              label="Estado"
              className={style.input}
              value={dadosCep.uf}
            />
            <Input
              disabled
              placeholder="Cidade"
              label="Cidade"
              className={style.input}
              value={dadosCep.localidade}
            />
            <Input
              disabled
              placeholder="Logradouro"
              label="Logradouro"
              className={style.input}
              value={dadosCep.logradouro}
            />
          </>
        )}

        {etapa == 2 && (
          <>
            <Input
              variant="bordered"
              labelPlacement="inside"
              description="Informe seu preço com apenas números"
              placeholder="Exemplo : R$ 50.000,00"
              label="Preço"
              className={style.input}
              maxLength={11}
              max={8}
              value={`R$ ${preco}`}
              onChange={(x) => mascaraFinanceira(x.target.value)}
            />
          </>
        )}

        {etapa == 3 && (
          <>
            <Input
              variant="bordered"
              labelPlacement="inside"
              description="Informe a quilometragem real com apenas números"
              placeholder="Exemplo : 50.000 Km"
              label="Km"
              className={style.input}
              maxLength={7}
              value={`${quilometragem}`}
              onChange={(x) => mascaraKm(x.target.value)}
            />
          </>
        )}

        {etapa == 4 && (
          <>
            <UploadButton
              options={options}
              onComplete={(files) =>
                files.map((file) => {
                  addFoto(file.fileUrl);
                })
              }
            >
              {({ onClick }) => (
                <button className={style.button_upload} onClick={onClick}>
                  <FileUploadIcon />
                </button>
              )}
            </UploadButton>

            <div className={style.fotos}>
              <Gallery>
                <Swiper
                  modules={[Navigation]}
                  spaceBetween={50}
                  slidesPerView={2}
                  navigation
                >
                  {fotos.map((foto, index) => {
                    return (
                      <SwiperSlide>
                        <Item
                          original={foto}
                          thumbnail={foto}
                          width="753"
                          height="560"
                        >
                          {({ ref, open }) => (
                            <div key={index} onClick={() => removeFoto(foto)}>
                              <img ref={ref} onClick={open} src={foto} alt="" />
                            </div>
                          )}
                        </Item>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </Gallery>
            </div>
          </>
        )}

        {etapa == 5 && (
          <CheckboxGroup
            className={style.checkbox}
            orientation="horizontal"
            color="white"
            defaultValue={["buenos-aires", "london"]}
          >
            <Checkbox className={style.item} value="condicionado">
              Ar condicionado
            </Checkbox>
            <Checkbox value="airbags">Air bags</Checkbox>
            <Checkbox value="aquecimento">Aquecimento do banco</Checkbox>
            <Checkbox value="vidro-e">Vidros eletrícos</Checkbox>
            <Checkbox value="trava-e">Trava eletríca</Checkbox>
            <Checkbox value="teto-solar">Teto solar</Checkbox>
            <Checkbox value="teto-solar">Cruise control</Checkbox>
            <Checkbox value="teto-solar">Banco de couro</Checkbox>
            <Checkbox value="teto-solar">Blindado</Checkbox>
            <Checkbox value="teto-solar">Licenciamento pago</Checkbox>
            <Checkbox value="teto-solar">IPVA Pago</Checkbox>
            <Checkbox value="teto-solar">Direção eletríca</Checkbox>
            <Checkbox value="teto-solar">Direção hidraúlica</Checkbox>
            <Checkbox value="teto-solar">Multímidia</Checkbox>
            <Checkbox value="teto-solar">Camera de ré</Checkbox>
          </CheckboxGroup>
        )}

        {etapa != 0 && (
          <Button
            disabled={!isvalid}
            onClick={() => setEtapa(etapa - 1)}
            className={style.button_voltar}
          >
            Voltar
          </Button>
        )}

        {etapa != 6 && (
          <Button
            disabled={!isvalid}
            onClick={() => validator(etapa)}
            className={style.button}
          >
            {etapa == 0 && "Adicionar endereço"}

            {etapa == 1 && "Adicionar preço"}

            {etapa == 2 && "Adicionar quilometragem"}

            {etapa == 3 && "Adicionar fotos"}

            {etapa == 4 && "Adicionar opcionais"}

            {etapa == 5 && "Adicionar anúncio"}
          </Button>
        )}
      </div>
    </NextUIProvider>
  );
}
