import {
  Button,
  Dropdown,
  DropdownItem,
  FileInput,
  Label,
  Table,
  TextInput,
} from "flowbite-react";
import Navbar from "../../componentes/navbar/Navbar";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import HomeIcon from "@mui/icons-material/Home";
import TimeToLeaveOutlinedIcon from "@mui/icons-material/TimeToLeaveOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CloseIcon from "@mui/icons-material/Close";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import "./Dashboard.css";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Item } from "react-photoswipe-gallery";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { UploadButton } from "@bytescale/upload-widget-react";
import CarroTab from "../CarroTab/CarroTab";
import PerfilTab from "../PerfilTab/PerfilTab";

function Dashboard() {

  useEffect(() => {
    fetch("https://webapicarlab.azurewebsites.net/Carro", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }})
  }, [])

function setNewTab(tabIndex){
  if(tabIndex == 1){
    setCarroTab(true);
    setPerfilTab(false);
  }

  if(tabIndex == 2){
    setCarroTab(false);
    setPerfilTab(true);
  }
}
  const [carroTab, setCarroTab] = useState(true);
  const [perfilTab, setPerfilTab] = useState(false);

  return (
    <div className="central">
      <Navbar />

      <div className="container-central">
        <div
          className="mr-2 rounded w-1/6 flex flex-col desktop header-admin"
          style={{ height: "50vh" }}
        >
          <div
            className=" svg-white ml-1 text-white w-full flex rounded-md items-center justify-center gap-2 hover-gray"
            style={{ height: "10vh" }}
          >
            <HomeIcon />
            <span className=" font-semibold">Home</span>
          </div>

          <div
            className=" svg-white ml-1 text-white w-full flex rounded-md items-center justify-center gap-2 hover-gray"
            style={{ height: "10vh" }}
            onClick={() => setNewTab(2)}
          >
            <AccountBoxOutlinedIcon />
            <span className=" font-semibold">Perfil</span>
          </div>

          <div
            className=" svg-white ml-1 text-white w-full flex rounded-md items-center justify-center gap-2 hover-gray"
            style={{ height: "10vh" }}
            onClick={() => setNewTab(1)}
          >
            <TimeToLeaveOutlinedIcon />
            <span className=" font-semibold">Meus carros</span>
          </div>

          <div
            className=" svg-white ml-1 text-white w-full flex rounded-md items-center justify-center gap-2 hover-gray"
            style={{ height: "10vh" }}
          >
            <MessageOutlinedIcon />
            <span className=" font-semibold">Mensagens</span>
          </div>

          <div
            className=" svg-white ml-1 text-white w-full flex rounded-md items-center justify-center gap-2 hover-gray"
            style={{ height: "10vh" }}
          >
            <AttachMoneyIcon />
            <span className=" font-semibold">Financeiro</span>
          </div>

          <div
            className=" svg-white ml-1 text-white w-full flex rounded-md items-center justify-center gap-2 hover-gray"
            style={{ height: "10vh" }}
          >
            <LogoutOutlinedIcon />
            <span className=" font-semibold">Logout</span>
          </div>
        </div>

        {
          carroTab && (
            <CarroTab/>
          )
        }

{
          perfilTab && (
            <PerfilTab/>
          )
        }
      </div>
    </div>
  );
}

export default Dashboard;
