import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownSection,
  DropdownItem,
} from "@nextui-org/react";
import { Button, ButtonGroup } from "@nextui-org/react";
import { useMemo, useState } from "react";

export default function DropdownNative(props) {
  const [contador, setContador] = useState(0);
  const [selectedKeys, setSelectedKeys] = useState(new Set([contador == 0 ? props.label : ""]));

  const selectedValue = useMemo(
    () => Array.from(selectedKeys).join(", ").replaceAll("_", " "),
    [selectedKeys]
  );

  return (
    <>
      <Dropdown>
        <DropdownTrigger>
          <Button variant="bordered" className="capitalize">
            {selectedValue}
          </Button>
        </DropdownTrigger>
        <DropdownMenu
          aria-label="Multiple selection example"
          variant="flat"
          closeOnSelect={false}
          disallowEmptySelection
          selectionMode="single"
          selectedKeys={selectedKeys}
          onSelectionChange={setSelectedKeys}
        >
          <DropdownItem key="Preto">Preto</DropdownItem>
          <DropdownItem key="Branco">Branco</DropdownItem>
          <DropdownItem key="Vermelho">Vermelho</DropdownItem>
          <DropdownItem key="Azul">Azul</DropdownItem>
          <DropdownItem key="Cinza">Cinza</DropdownItem>
          <DropdownItem key="Prata">Prata</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
}
