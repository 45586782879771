import { useEffect, useState } from "react";
import Buscador from "../../../componentes/buscador/Buscador";
import Conteudo from "../../../componentes/conteudo/Conteudo";
import Navbar from "../../../componentes/navbar/Navbar";
import Veiculos from "../../../componentes/veiculos/Veiculos";
import "./CarrosLista.css";
function CarrosLista() {
  const [marcas, setMarcas] = useState();
  const [modelos, setModelos] = useState();
  const [carros, setCarros] = useState([]);
  const [carrosBackup, setCarrosBackup] = useState([{}]);
  const [filtrar, setFiltrar] = useState(false);
  useEffect(() => {
    if(localStorage.getItem("marcaname") == null || undefined){
        fetch("https://webapicarlab.azurewebsites.net/Carro")
        .then((response) => response.json())
        .then((resultado) => setCarros(resultado))
        .catch((err) => {
          console.error("ops! ocorreu um erro" + err);
        });
    }
  }, []);

  useEffect(() => {
    fetch("https://parallelum.com.br/fipe/api/v2/cars/brands")
      .then((response) => response.json())
      .then((resultado) => setMarcas(resultado))
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  useEffect(() => {
    if(localStorage.getItem("marcaname")){
        Filtrar();
    }
  }, [])

  function GetModelos(marca = localStorage.getItem("marca")) {
    fetch(
      "https://parallelum.com.br/fipe/api/v2/cars/brands/" +
        marca.code +
        "/models"
    )
      .then((response) => response.json())
      .then((resultado) => setModelos(resultado))
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function Filtrar() {
    fetch("https://webapicarlab.azurewebsites.net/Carro/GetCarroFiltros", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        marca: localStorage.getItem("marcaname"),
        modelo: localStorage.getItem("modeloname") || " ",
        cor: localStorage.getItem("cor") || "",
        ano: "",
        categoria : localStorage.getItem("categoria") || "",
        cidade : localStorage.getItem("cidade") || "",
        estado : localStorage.getItem("estado") || ""
      }),
    })
      .then((response) => response.json())
      .then((resultado) => setCarros(resultado))
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  return (
    <div className="central">
      <Navbar />
      <Buscador filtrar={(x) => Filtrar()} />
      <Veiculos buscador={true} carros={carros} titulo={"Todos os carros"} />
    </div>
  );
}

export default CarrosLista;
