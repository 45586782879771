import "./Buscador.css";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import TuneIcon from "@mui/icons-material/Tune";
import { Dropdown, DropdownItem } from "flowbite-react";
import { useState, useEffect } from "react";
import { NextUIProvider } from "@nextui-org/react";
import {
  Autocomplete,
  AutocompleteSection,
  AutocompleteItem,
} from "@nextui-org/react";
import DropdownNative from "../dropdown/DropdownNative";

function Buscador(props) {
  const [condicao, setCondicao] = useState(localStorage.getItem("condicao"));
  const [modelo, setModelo] = useState(localStorage.getItem("modeloname"));
  const [marca, setMarca] = useState(localStorage.getItem("marcaname"));
  const [apareceFiltros, setApareceFiltros] = useState(false);
  const [modelos, setModelos] = useState([]);
  const [marcas, setMarcas] = useState();
  const [combustivel, setCombustivel] = useState({
    nome: "Combustivél",
    id: 0,
    options: ["Combustivél", "Gasolina", "Eletríco", "Álcool"],
    label: "Combustivel",
  });

  const [filtros, setFiltros] = useState({
    combustivel: {
      options: [
        { name: "Combustivél", id: 0, selecionado: false },
        { name: "Gasolina", id: 1, selecionado: false },
      ],
      label: "Combustivel",
    },

    estados: {
      options: [],
      label: "Estados",
    },
  });

  const [cidades, setCidades] = useState([]);

  useEffect(() => {
    fetch("https://parallelum.com.br/fipe/api/v2/cars/brands")
      .then((response) => response.json())
      .then((resultado) => setMarcas(resultado))
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  useEffect(() => {
    fetch("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
      .then((response) => response.json())
      .then((resultado) => GetEstados(resultado))
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("marcaId") &&
      localStorage.getItem("marcaId") != "0"
    ) {
      fetch(
        "https://parallelum.com.br/fipe/api/v2/cars/brands/" +
          localStorage.getItem("marcaId") +
          "/models"
      )
        .then((response) => response.json())
        .then((resultado) => setModelos(resultado))
        .catch((err) => {
          console.error("ops! ocorreu um erro" + err);
        });
    }
  }, []);

  function GetEstados(estados) {
    var data = filtros;
    data.estados.options = estados;
    setFiltros(data);
  }

  async function GetCidades(sigla) {
    setCidades([]);
    await fetch(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${sigla}/municipios`
    )
      .then((response) => response.json())
      .then((x) => setCidades(x));
  }

  function GetModelos(marca) {
    setMarca(marca.name);
    setModelo("Qualquer modelo");
    localStorage.setItem("modeloname", "Qualquer modelo");
    localStorage.setItem("modeloId", 0);
    console.log(marca);
    if (marca.code != 0 && marca.code != "0") {
      fetch(
        "https://parallelum.com.br/fipe/api/v2/cars/brands/" +
          marca.code +
          "/models"
      )
        .then((response) => response.json())
        .then((resultado) => setModelos(resultado))
        .catch((err) => {
          setModelos([]);
          console.error("ops! ocorreu um erro" + err);
        });
    } else {
      setModelos([]);
    }
  }

  function addFiltro(filtro, option) {
    filtro.label = option;
    setCombustivel(filtro);
    console.log(combustivel);
  }

  function Filtrar() {}

  return (
    <NextUIProvider>
      <div className="div flex items-center justify-center w-full">
        <div className="modal-pesquisa w-full">
          <div className="select-estiloso">
            <div className="flex">
              <Dropdown label={condicao} inline className=" bg-white">
                {" "}
                <Dropdown.Item
                  onClick={(x) => {
                    setCondicao("Usado");
                    localStorage.setItem("condicao", "Usado");
                  }}
                >
                  Usado
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={(x) => {
                    setCondicao("Novo");
                    localStorage.setItem("condicao", "Novo");
                  }}
                >
                  Novo
                </Dropdown.Item>
              </Dropdown>
            </div>
          </div>

          <div className="linha"></div>

          <div className="select-estiloso">
            <div className="flex">
              <Dropdown
                label={marca}
                inline
                className=" bg-white max-h-48 overflow-x-auto"
              >
                {" "}
                {marcas != undefined &&
                  marcas.map((marca) => {
                    return (
                      <Dropdown.Item
                        onClick={(x) => {
                          GetModelos(marca);
                          localStorage.setItem("marcaname", marca.name);
                          localStorage.setItem("marcaId", marca.code);
                          if (marcas[0].name != "Qualquer marca") {
                            var marcasProp = marcas;
                            marcasProp.unshift({
                              name: "Qualquer marca",
                              code: "0",
                            });
                            setMarcas(marcasProp);
                          }
                        }}
                      >
                        {marca.name}
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown>
            </div>
          </div>

          <div className="linha"></div>

          <div className="select-estiloso">
            <div className="flex">
              <Dropdown
                label={modelo}
                inline
                className=" bg-white max-h-48 overflow-x-auto"
              >
                {" "}
                {modelos != undefined &&
                  modelos.map((modelo) => {
                    return (
                      <Dropdown.Item
                        onClick={(x) => {
                          setModelo(modelo.name);
                          localStorage.setItem("modeloname", modelo.name);
                          localStorage.setItem("modeloId", modelo.code);
                          if (modelos[0].name != "Qualquer modelo") {
                            var modeloProp = modelos;
                            modeloProp.unshift({
                              name: "Qualquer modelo",
                              code: "0",
                            });
                            setModelos(modeloProp);
                          }
                        }}
                      >
                        {modelo.name}
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown>
            </div>
          </div>

          <div className="linha"></div>
          <div
            className="final-div"
            onClick={() => setApareceFiltros(!apareceFiltros)}
          >
            <TuneIcon />
            <span> {apareceFiltros ? "Fechar filtros" : "Mais filtros"}</span>
          </div>

          {apareceFiltros && (
            <div className="filtros grid pl-10 pr-10 pb-10 items-start pt-5 gap-5 grid-cols-3 absolute z-10 bg-white h-48 rounded-xl top-64">
              <div>
                <Autocomplete
                  defaultItems={filtros.estados.options}
                  label="Estado"
                  placeholder="Selecione o estado"
                  className="auto-complete"
                >
                  {(filtro, index) => (
                    <AutocompleteItem
                      onClick={() => GetCidades(filtro.sigla)}
                      key={index}
                    >
                      {filtro.nome}
                    </AutocompleteItem>
                  )}
                </Autocomplete>
              </div>

              <div>
                <Autocomplete
                  defaultItems={cidades}
                  label="Cidade"
                  placeholder="Selecione a cidade"
                  className="auto-complete"
                >
                  {(filtro, index) => (
                    <AutocompleteItem key={index}>
                      {filtro.nome}
                    </AutocompleteItem>
                  )}
                </Autocomplete>
              </div>

              <div>
                <DropdownNative label={"Selecione a cor"} />
              </div>

              <div className=" border rounded-sm" onClick={() => setApareceFiltros(false)}>
                <div className="button-filtros">Filtrar</div>
              </div>

              <div className=" border rounded-sm">
                <div className="button-filtros neutral">Limpar filtros</div>
              </div>
            </div>
          )}

          <div className="btn-pesquisa" onClick={() => props.filtrar()}>
            <SearchIcon />
            Buscar carros
          </div>
        </div>
      </div>
    </NextUIProvider>
  );
}

export default Buscador;
