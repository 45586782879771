import { Button, FileInput, Label, TextInput } from "flowbite-react";
import "./PerfilTab.css";
import { useState } from "react";
function PerfilTab() {
  const [atualizar, setAtualizar] = useState(true);
  return (
    <div className="perfilDiv">
      <div>Perfil</div>
      {atualizar ? (
        <>
          <div className="conteudo-perfil">
            <div>
              <div className="mb-2 block">
                <Label htmlFor="email1" value="Seu email" />
              </div>
              <TextInput
                id="email1"
                type="email"
                placeholder="admin@gmail.com"
                required
                disabled
              />
            </div>

            <div>
              <div className="mb-2 block">
                <Label htmlFor="nome" value="Seu nome" />
              </div>
              <TextInput id="nome" type="email" placeholder="Admin" required />
            </div>

            <div>
              <div className="mb-2 block">
                <Label htmlFor="telefone" value="Seu celular" />
              </div>
              <TextInput
                id="telefone"
                type="tel"
                placeholder="admin@gmail.com"
                required
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="cep" value="CEP" />
              </div>
              <TextInput
                id="cep"
                type="text"
                placeholder="Rua adamantina"
                required
              />
            </div>

            <div id="fileUpload" className="max-w-md">
              <div className="mb-2 block">
                <Label htmlFor="file" value="Foto de perfil" />
              </div>
              <FileInput
                id="file"
                helperText="Sua foto será exibida junto ao anúncio do seu veículo"
              />
            </div>
          </div>

          <Button className="button-atualizar">Atualizar</Button>
        </>
      ) : (
        <>
        <img src="" alt="error"/>
        <span>Admin</span>
        <span>Amin@gmail.com</span>
        <span>lorem10 sdadad</span>
        </>
      )}
    </div>
  );
}

export default PerfilTab;
