import "./Veiculos.css";
import "../../cores/cores.css";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import SpeedIcon from "@mui/icons-material/Speed";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import RemoveIcon from "@mui/icons-material/Remove";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import { Link } from "react-router-dom";
import { Dropdown, DropdownItem, Spinner } from "flowbite-react";
import { useEffect, useState } from "react";
import PlaceIcon from '@mui/icons-material/Place';
function Veiculos(props) {
  const [carros, setCarros] = useState([{}]);
  const [marcaNome, setmarcaname] = useState(localStorage.getItem("marcaname"));
  useEffect(() => {
    fetch("https://webapicarlab.azurewebsites.net/Carro")
      .then((response) => response.json())
      .then((resultado) => setCarros(resultado))
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  return (
    <div
      className="veiculos"
      style={{
        marginTop: props.buscador ? "0rem" : "2rem",
        borderTopRightRadius: props.buscador ? "0px" : "30px",
        borderTopLeftRadius: props.buscador ? "0px" : "30px",
      }}
    >
      <div className="header-marcas">{props.titulo}</div>
      {!props.buscador && (
        <div className="ordenar">
          <div className="flex-row">
            <div className="select-ordenado">
              <Dropdown label="Ordenar por" color="blue">
                <DropdownItem className=" w-full">Menor preço</DropdownItem>

                <DropdownItem className=" w-full">Menor Km</DropdownItem>

                <DropdownItem className=" w-full">Mais novo</DropdownItem>
              </Dropdown>
            </div>
          </div>
        </div>
      )}

      {props.carros != undefined && props.carros.length > 0 && (
        <div className="lista-carros">
          {props.carros.length > 0 ? (
            props.carros.map((x) => {
              return (
                <div className="card-carro" key={x.id}>
                  <img src={x.fotos[0]} className="img-carro" />
                  <div className="marcador-div">
                    {x.lowkm && (
                      <div className="low-km">
                        Baixo Km
                        <SpeedIcon />
                      </div>
                    )}

                    {x.lowprice && (
                      <div className="low-price">
                        Bom preço
                        <AttachMoneyOutlinedIcon />
                      </div>
                    )}
                  </div>
                  <div className="infos-carro">
                    <div className="nome-carro">
                      <span>{x.nome}</span>
                      <span>{x.modelo}</span>
                    </div>
                    <div className="linha"></div>
                    <div className="subinfos">
                      <div className="icon-div">
                        <SpeedIcon />
                        <span>{x.quilometragem} Km</span>
                      </div>

                      <div className="icon-div">
                        <LocalGasStationIcon />
                        <span>{x.combustivel}</span>
                      </div>

                      <div className="icon-div">
                        <DirectionsCarIcon />
                        <span>{x.cambio}</span>
                      </div>
                    </div>
                    <div className="linha"></div>
                    <div className="div-final">
                      <span className="price">R$ {x.preco}</span>
                      <Link to={"/detalhes/" + x.id}>
                        <div className="btn-action">
                          <span>Ver mais</span>
                          <ArrowOutwardIcon />
                        </div>
                      </Link>
                    </div>

                    <div className="linha endereco flex items-start justify-start">
                      <PlaceIcon/>
                      <span>{x.lojaEndereco}</span>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="w-full flex items-center justify-center">
              <Spinner
                aria-label="Extra large spinner example Center-aligned w-full"
                size="xl"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Veiculos;
