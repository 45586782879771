import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react"
import Home from "./Views/Home/Home";
import CarrosLista from "./Views/Home/CarrosLista/CarrosLista";
import ViewCarro from "./Views/ViewCarro/ViewCarro";
import Login from "./Views/Login/Login";
import Dashboard from "./Views/Dashboard/Dashboard";
import Vendas from "./Views/Vendas/Vendas";
localStorage.setItem("condicao", "Usado");
localStorage.setItem("marcaname", "Qualquer marca");
localStorage.setItem("cidade", "");
localStorage.setItem("estado", "");
localStorage.setItem("modeloname", "Qualquer modelo");
localStorage.setItem("categoria", "");
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "/login",
    element: localStorage.getItem("token") ? (
      <Dashboard/>
    ) : (
      <Login/>
    ),
  },
  {
    path: "/detalhes/:id",
    element: <ViewCarro/>,
  },
  {
    path: "/home",
    element: <Home/>,
  },
  {
    path: "/dashboard",
    element: localStorage.getItem("token") ? (
      <Dashboard/>
    ) : (
      <Login/>
    ),
  },
  {
    path: "/Carro",
    element: <CarrosLista/>,
  },

  {
    path: "/Vender",
    element: <Vendas/>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Analytics/>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
